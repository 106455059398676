import Vue from 'vue'
//import Vue from 'vue/dist/vue.js';

import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'

import debounce from 'lodash/debounce';




import VModal from 'vue-js-modal';


//import cookie from './plugins/cookie'

import VueCookies from 'vue-cookies'
import 'sweetalert2/dist/sweetalert2.min.css';


import router from './router'
import vuetify from './plugins/vuetify'


import api from './utils/api';

export const bus = new Vue();

//Define app                 
window.context = "emt";

document.title = "EMT";

Vue.$api = api

Object.defineProperty(Vue.prototype, '$api', {
  get() {
    return api
  }
})


//import VCalendar from 'v-calendar';

/* import Timesheet from "./components/timesheet.vue";
import Login from "./components/login.vue"; */

/* const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [

      { path: '/timesheet', component: Timesheet },
      { path: '/login', component: Login }
    ]
  });
 */
/*   Vue.use(VCalendar, {
                // ...other defaults
  }); */


/*   import { IonicVue } from '@ionic/vue';
  import routerionic from './routerionic'; */

//Vue.config.productionTip = false;

var loggedIn = window.$cookies.get("token");
//console.log(loggedIn);



Vue.use(VueCookies);
Vue.use(debounce);

Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(VueRouter)


Vue.use(VModal, {
  dialog: true
});

Vue.config.ignoredElements = [/ion:*/]

/* Vue.use(Ionic); */

if (loggedIn) {
  Vue.prototype.userLogged = true;

}
//Vue.prototype.hideToolbars = true  ;



// parte notifiche



window.App = new Vue({
  router,
  vuetify,

  render: h => h(App)
}).$mount('#app')



if (loggedIn == null) {


  if (!document.location.hash.toString().includes("reservateCespiti") && (!document.location.hash.toString().includes("reservateCustomerSupplier")) && (!document.location.hash.toString().includes("reservate"))) {

    // alert("ok");


    router.push({ path: '/' }).catch(() => { });

  }






}


//export const bus = window.App;


// if (   loggedIn == null    )
//     {
//         router.push({path: '/'}).catch(()=>{});
//         //this.$router.push("/admin").catch(()=>{});
//     }


/*  new Vue({
    router: router,

    template: `
      <div id="app">

        <ul>
          <li><router-link to="/login">/</router-link></li>
          <li><router-link to="/timesheet">/timesheet</router-link></li>
        </ul>
        <router-view class="view"></router-view>
      </div>
    `
  }).$mount('#app' ) */
//})






<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_emt_mail_log">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>From</th>
                                        <th class='desktop'>Name</th>
                                        <th class='all'>To</th>
                                        <th class='desktop'>Subject</th>
                                        <th class='desktop'>Inserted</th>
                                        <th class='all' style="text-align: center;">Sent</th>
                                        <th class='desktop'>Date Sent</th>
                                        <th class='all' style="text-align: center;">Result</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="10">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="" id="fldSearch">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/icon_delete_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contsent">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetSent">
                        <v-sheet class="text-center" height="360px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeSentSheet' icon @click="sheetSent = !sheetSent">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_stati">
                                Sent
                            </div>

                            <v-list-item v-for="tile in allSent" :key="tile.title" @click="filterSent(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar :size="tile.sizepx" tile>

                                        <v-img alt="" contain :src="tile.img" :min-width="tile.size"
                                            :min-height="tile.size" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                    tile.title
                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>

        <div class="contresult">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetResult">
                        <v-sheet class="text-center" height="360px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeSentSheet' icon @click="sheetResult = !sheetResult">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_stati">
                                Result
                            </div>

                            <v-list-item v-for="tile in allResult" :key="tile.title" @click="filterResult(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar :size="tile.sizepx" tile>

                                        <v-img alt="" contain :src="tile.img" :min-width="tile.size"
                                            :min-height="tile.size" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                    tile.title
                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>


        <div class="contpopupbody" style="display:none">

            <template>
                <modal name="popupBody" :clickToClose="false" :width="262" :height="434">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Body Mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="margin-left:10px;min-height: 300px;max-height: 300px;
    overflow-x: auto;
    overflow-y: auto;" id="txtBody"></div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-body" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelBody" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" label="Da" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataA" v-model="dataA" label="A" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />


                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





    </div>
</template>


<script>

import {
    bus
} from "../main";
//import router from ".././router";
/* import $ from 'jquery'; */

import apilogmail from "../utils/logmail/apilogmail";



export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

   
        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        this.$root.$children[0].showAziendaSelezionata = false;

        this.$root.$children[0].checkLogout();

        // used for load users associated to group
        window.$cookies.set("is_load_users_from_group", 1, "9y");

        var pointerVue = this;

        window.myVal = "";

        window.curSent = 0 // tutti inviati e non
        window.curResult = 0 // tutti ok e errori
        window.curSrcText = "";
        window.dataDa = "";
        window.dataA = "";


        window.perpage = 100;
        bus.$off("bottomIconClickEvent");

        $(document).ready(
            function () {

                $("body").off();

            }
        );


        $(document).ready(function () {


            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);


                window.dataDa = pointerVue.dataDa;
                window.dataA = pointerVue.dataA;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();


            });



            $('body').on('click', '.seldata', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });



            $('body').on('click', '.cancelfilters', function () {

                console.log(("EVENTO CLICK SELANNO"));



                window.curSent = 0 // tutti inviati e non
                window.curResult = 0 // tutti ok e errori
                window.curSrcText = "";
                window.dataDa = "";
                window.dataA = "";

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });

            $('body').on('click', '.selrefresh', function () {

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });



            $('body').on('click', '.selsent', function () {

                pointerVue.sheetSent = true;

            });


            $('body').on('click', '.selresult', function () {

                pointerVue.sheetResult = true;

            });



            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));


            });

            


        });


        

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            // var ids = "";

            switch (data) {

                case "btn_delete":


                    this.deleteRow();




                    break;

                case "btn_body":


                    var sel_chk = null;

                    // var that = this;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            sel_chk = $(this);

                        }

                    });

                    console.log("SEL CHK:", sel_chk);

                    var sel_row = sel_chk.parent().parent();

                    console.log("SEL ROW:", sel_row);

                    var row_data = window.table.row(sel_row).data();

                    console.log("ROW DATA:", row_data);

                    $(".contpopupbody").show();

                    this.$modal.show('popupBody');



                    setTimeout(() => {

                        $("#txtBody").html(row_data.mail_body);

                    }, 200);



                    break;


                default:
                    break;

            }
        });


        this.setupButtonsGrid();

        window.start_ = 0;

    

        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);

       

    },
    data: () => ({

        dataDa: null,
        showPicker3: false,

        dataA: null,
        showPicker4: false,

        allSent: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 0,
                icon: "mdi-account-lock-open",
                "color": "red",
                size: 20,
                sizepx: "20px"
            },


            {
                img: 'https://app.emtool.eu/public/_lib/img/green_mark_ok.png',
                title: 'Si',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                size: 16,
                sizepx: "16px"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/red_cross_icon_2.png',
                title: 'No',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                size: 16,
                sizepx: "16px"
            },


        ],

        allResult: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 0,
                icon: "mdi-account-lock-open",
                "color": "red",
                size: 20,
                sizepx: "20px"
            },


            {
                img: 'https://app.emtool.eu/public/_lib/img/usr__NM__bg__NM__green_ball.png',
                title: 'Ok',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                size: 12,
                sizepx: "12px"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/usr__NM__bg__NM__red_ball.png',
                title: 'Error',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                size: 12,
                sizepx: "12px"
            },


        ],

        sheetResult: false,


        sheetSent: false,


        valueOtpDelete: "",

        sheetAnno: false,


        anni: [{ id: "", nome: "Tutti" }, { id: "2025", nome: "2025" }, { id: "2024", nome: "2024" }, { id: "2023", nome: "2023" }, { id: "2022", nome: "2022" }, { id: "2021", nome: "2021" }, { id: "2020", nome: "2020" }, { id: "2019", nome: "2019" }, { id: "2018", nome: "2018" }, { id: "2017", nome: "2017" }, { id: "2016", nome: "2016" }, { id: "2015", nome: "2015" }],

        sheetIntMand: false,

        intestatario_mandato: [],

        sheetPagamenti: false,

        stati_pagamento: [],

        gestori: [],

        searchGestore: "",

        sheetGestori: false,

        sheetReferenti: false,

        sheetStatus: false,

        sheetProjectType: false,

        sheetControlType: false,

        sheetAziende: false,
        search: "",

        textSearch: "",

        aziende: [],

        projectType: [],

        controlTypeTemp: [],

        controlType: [],


        referentiTemp: [],

        referenti: [],

        // stati: [{ id: "", nome: "Tutti" }, { id: "Y", nome: "Si" }, { id: "N", nome: "No" }, { id: "1", nome: "NA" }, { id: "2", nome: "WIP" }],

        stati: [],

        showPicker: false,
        showPicker2: false,

        dataDal: null,
        dataAl: null,

        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

        filteredGestori() {
            return _.orderBy(this.gestori.filter(item => {
                if (!this.searchGestore) return this.gestori;
                return (item.name.toLowerCase().includes(this.searchGestore.toLowerCase()));
            }), 'headline')


        },





    },


    methods: {


        reloadData: function() {

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

            setTimeout(() => {
                
                this.reloadData();

            }, 10000);
        },


        btnResetSearch: function () {



            window.curSrcText = "";


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },


        deleteRow: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apilogmail.deleteLogMail(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteLogMail", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();


                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        viewCancelFiltri: function () {

            var cancelFiltri = 0;

            if (window.curSent != 0) {
                cancelFiltri = 1;
            }

            if (window.curResult != 0) {
                cancelFiltri = 1;
            }

            if (window.curSrcText != "") {
                cancelFiltri = 1;
            }

            if (window.dataDa != "") {
                cancelFiltri = 1;
            }

            if (window.dataA != "") {
                cancelFiltri = 1;
            }


            console.log("CANCEL FILTRI: ", cancelFiltri);

            if (cancelFiltri == 0) {

                $((".cancelfilters")).hide();

            }

            if (cancelFiltri == 1) {

                $((".cancelfilters")).show();

            }


        },


        btnCancelBody: function () {

            this.$modal.hide('popupBody');



        },



        filterSent: async function (status) {

            console.log(status);

            this.sheetSent = false;

            var v_filter = 0;

            if (status == "Si") {
                v_filter = 1;
            }
            else if (status == "No") {
                v_filter = 2;
            }



            window.curSent = v_filter;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();



        },



        filterResult: async function (status) {

            console.log(status);

            this.sheetResult = false;

            var v_filter = 0;

            if (status == "Ok") {
                v_filter = 1;
            }
            else if (status == "Error") {
                v_filter = 2;
            }



            window.curResult = v_filter;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();



        },




        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }


            });

            setTimeout(() => {

                var pulsantis = [];


                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/body-mail-det.png",
                        link: "/info",
                        id: "btn_body",
                        disabled: false,
                        title: "Body",
                        width: 25
                    });


                }


                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Delete",
                        width: 30
                    });


                }





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            window.$cookies.set("filter_search_text_pratiche", this.textSearch, "9y");

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlApiDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/doxweb/" + nome_metodo;

        },

        getUrlPublicApiDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/doxweb/" + nome_metodo;

        },

        setupGrid: function () {





            var filtri = `<img src='/emt/img/refresh-emt.png' style='border:0!important;' class='selrefresh' title='Refresh' /><img src='/emt/img/filtro-stato-emt.png' style='border:0!important;' class='selsent' title='Sent' /><img src='/emt/img/result_log_mail.png' style='border:0!important;' class='selresult' title='Result' /><img src='/emt/img/calendar-emt.png' style='border:0!important;' class='seldata' title='Select Date' /><img src='/emt/img/delete_filters_emt.png' style='border:0!important;' class='cancelfilters' title='Delete All Filters' />`;




            var pointerVue = this;



            const date = new Date().toJSON().slice(0, 10)



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                console.log("TOKEN: ", v_token);


                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        name: 'sel_row',
                        width: '1%'
                    },

                    {
                        data: 'row_id',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'mail_from',
                        orderable: true,
                        name: 'mail_from',
                        width: '2%'
                    },

                    {
                        data: 'from_name',
                        orderable: true,
                        name: 'from_name',
                        width: '4%'
                    },

                    {
                        data: 'mail_to',
                        orderable: true,
                        name: 'mail_to',
                        width: '1%'
                    },

                    {
                        data: 'mail_subject',
                        orderable: true,
                        name: 'mail_subject',
                        width: '6%'
                    },

                    {
                        data: 'date_inserted',
                        orderable: true,
                        name: 'date_inserted',
                        width: '2%',
                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }


                    },

                    {
                        data: 'sent',
                        orderable: false,
                        name: 'sent',
                        width: '1%'
                    },

                    {
                        data: 'date_sent',
                        orderable: true,
                        name: 'date_sent',
                        width: '2%',
                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'result',
                        orderable: false,
                        name: 'result',
                        width: '1%'
                    },


                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,


                    ajax: {
                        url: pointerVue.getUrlDomain("EmtMailLog") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start_ = window.perpage;

                            valori.start_ = 0;
                            valori.length_ = window.perpage;



                            valori.curSrcText = window.curSrcText;
                            valori.curSent = window.curSent;
                            valori.curResult = window.curResult;

                            valori.dataDa = window.dataDa;
                            valori.dataA = window.dataA;

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 1000);

                        pointerVue.viewCancelFiltri();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                        $('.p_contributo, .p_investimento, .p_previsionale').number(true, 2, ',', '.');




                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);






                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 800);

                            var valori = window.valori;
                            valori.start_ = window.start_;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtMailLog") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    //  $(".spin").hide();
                                    window.loading = 0;
                                    window.start_ += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Mail Log</div>

                    <div class="content_wrap_buttons"><div class="wrap_buttons">
                        ` + filtri + `
                        
                        </div></div>



                    


                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                    setTimeout(() => {

                        $("#fldSearch").focus();

                    }, 200);

                });

                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_emt_mail_log th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });




                $('body').on('click', '.cont_emt_mail_log tr', function () {


                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log(v_enable);

            setTimeout(() => {

                var pulsantis = [];

                // if (v_enable == false) {




                //     pulsantis.push({
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //         title: "Aggiungi",
                //         width: 30
                //     });

                //     if (ids != "") {

                //         pulsantis.push({
                //             text: "Delete",
                //             icon: "mdi-home-circle",
                //             image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                //             link: "/info",
                //             id: "btn_delete",
                //             disabled: false,
                //             title: "Elimina",
                //             width: 30

                //         });



                //     }

                //     pulsantis.push({
                //         text: "Esporta XLS",
                //         icon: "mdi-arrow-left",
                //         image: "https://app.emtool.eu/public/_lib/img/export-xls-emt.png",
                //         link: "/confOrariChoice",
                //         id: "btn_export_xls",
                //         disabled: false,
                //         title: "Esporta Excel",
                //         width: 30
                //     });





                // }

                // if (v_enable == true) {


                //     pulsantis.push({
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //         title: "Aggiungi",
                //         width: 30
                //     });

                //     pulsantis.push({
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //         title: "Modifica",
                //         width: 30

                //     });

                //     if (ids != "") {

                //         pulsantis.push({
                //             text: "Delete",
                //             icon: "mdi-home-circle",
                //             image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                //             link: "/info",
                //             id: "btn_delete",
                //             disabled: false,
                //             title: "Elimina",
                //             width: 30

                //         });



                //     }




                // }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [];

                // var pulsantis = [

                //     {
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //         width: 30
                //     },

                //     {
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //         width: 30
                //     },

                //     {
                //         text: "Delete",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                //         link: "/info",
                //         id: "btn_delete",
                //         disabled: false,
                //         width: 30
                //     },

                //     {
                //         text: "Esporta XLS",
                //         icon: "mdi-arrow-left",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-export-xls-emt.png",
                //         link: "/confOrariChoice",
                //         id: "btn_export_xls",
                //         disabled: false,
                //         title: "Esporta Excel",
                //         width: 25
                //     },

                //     {
                //         text: "Dashboard",
                //         icon: "mdi-home-circle",
                //         link: "/dash",
                //         id: "btn_exit",
                //         disabled: false,
                //     },

                // ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },














    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont_emt_mail_log .seldata {
    max-width: 27px;
    min-height: 27px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -3px;
}




.columnMailFrom {}

.columnMailTo {}


.columnRowId {}


.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.cont-table-pratiche {
    padding-top: 14px;
    overflow-x: auto !important;
    overflow-y: hidden;

}

.cont-table-pratiche .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_emt_mail_log .closeSentSheet {
    position: absolute;
    right: 0;
}


.cont_emt_mail_log {
    overflow-x: auto !important;
}

.cont_emt_mail_log #myTable {
    margin-right: 3px;
    width: 1085px !important;
}

.cont_emt_mail_log table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_emt_mail_log table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_emt_mail_log th {
    padding-left: 5px !important;
}

.cont_emt_mail_log ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_emt_mail_log .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_emt_mail_log div.dataTables_wrapper div.dataTables_filter input {}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_emt_mail_log #myTable_wrapper {
    min-width: initial;
    min-width: 1100px;
    max-width: 1100px;
    width: 1100px;
    font-size: 11px;
    color: black;
    font-weight: bold;
}

.cont_emt_mail_log .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_emt_mail_log .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_emt_mail_log .checkall {
    width: 13px;
    height: 13px;
}

.cont_emt_mail_log .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_emt_mail_log .val_status {
    width: 10px !important;
}


.cont_emt_mail_log .img_centered {
    text-align: center
}




.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetGestori {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}

.itemGestore {
    margin-left: 15px;
}


.cont_emt_mail_log .font-row-grid-datetime {
    font-size: 10px !important;
    width: 68px !important;
}

.cont_emt_mail_log .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cl-div-hid {
    display: none;
}

.cont_emt_mail_log .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.cont_emt_mail_log #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_emt_mail_log .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_emt_mail_log .seltipoprogetto {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_emt_mail_log .seltipo {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_emt_mail_log .selreferente {
    max-width: 24px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_emt_mail_log .selintmand {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_emt_mail_log .selanno {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_emt_mail_log .cancelfilters {
    max-width: 22px;
    min-height: 22px;
    margin-left: 42px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 22px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 2px;
}




.cont_emt_mail_log .seldata {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}



.cont_emt_mail_log .selgestore {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_emt_mail_log .selpagamento {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_emt_mail_log .selstato {
    max-width: 25px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_emt_mail_log .selsent {
    max-width: 26px;
    min-height: 26px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 26px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -1px;
}

.cont_emt_mail_log .selrefresh {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_emt_mail_log .selresult {
    max-width: 21px;
    min-height: 21px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 21px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 1px;
}


.cont_emt_mail_log .selmese {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/calendario-mesi-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_emt_mail_log .selstato2 {
    max-width: 24px !important;
    min-height: 24px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 24px !important;
    text-indent: -119px !important;
    background-size: 24px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/filtro-stato-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}



.cont_emt_mail_log .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_emt_mail_log .text_right2 {
    width: 100% !important;
    text-align: right !important;
}

.cont_emt_mail_log .content_wrap_buttons {}

.cont_emt_mail_log .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


@media screen and (max-width: 960px) {

    .cont_emt_mail_log .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_emt_mail_log #myTable {
        /* margin-top:54px; */
    }

    .cont_emt_mail_log #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_emt_mail_log .cont_buttons {
        height: 40px;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
    }

    .cont_emt_mail_log .content_wrap_buttons {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        max-width: 225px;
        height: 40px;
    }

    .cont_emt_mail_log .wrap_buttons {
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .columnMailFrom {
        width: 90px;
        min-width: 90px;
        max-width: 90px;
        font-weight: normal;
        font-size: 9px;
    }

    .columnMailTo {
        width: 62px;
        min-width: 62px;
        max-width: 62px;
        font-weight: normal;
        font-size: 9px;
    }


    .columnRowId {
        width: 10px;
        min-width: 10px;
        max-width: 10px;
        font-weight: normal;
        font-size: 9px;
    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_emt_mail_log #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_emt_mail_log #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_emt_mail_log .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_emt_mail_log #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_emt_mail_log .cl_num {
        margin-left: -2px !important;
    }



    .cont_emt_mail_log .cl-nome {
        width: 105px;
    }

    .font-row-grid-datetime {
        font-size: 10px !important;
        width: 100px !important;
        display: inline-table !important;
    }

    .cont_emt_mail_log .img_centered {
        display: inline;
    }

    .cont_emt_mail_log .cl_checkall {
        padding-left: 19px;
        padding-top: 2px;
    }




}
</style>

import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

var http = null; // not possible to create a private property in JavaScript, so we move it outside of the class, so that it's only accessible within this module

function getUrlDomain(nome_metodo)
{

  let result = "";

  if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
  }

  if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

  }

  return result + nome_metodo;


}

var main_domain = getUrlDomain("");

class APIProvider {
    constructor({ url }) {
        console.log(url);
        http = axios.create({
            baseURL: url,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

  

    tsGetTypeActivity(token) {

        var resource = "/tsGetTypeActivity";
        let formData = new FormData();

        formData.append("v_user", token);

        return http.post(resource, formData)
    }




    tsUpdateStatusApproval(token, system, user_id, status, year, month) {

        //var resource = "/tsSendForApprove";
        var resource = "/EmtSendForApproveApi";

        let formData = new FormData();

        console.log(system);
        formData.append("v_user_id", user_id);


        formData.append("auto_save", 1);

        formData.append("v_user", token);
        formData.append("v_status", status);


        formData.append("v_anno", year);
        formData.append("v_mese", month);

        return http.post(resource, formData)


    }

    LoadLogoCompanyNew2(token, nome_folder_prod) {
        var resource = "/tsLoadLogoCompanyNew";
        

        let formData = new FormData();
        formData.append("v_user", token);
        formData.append("v_nome_folder_prod", nome_folder_prod);


        return http.post(resource, formData)

    }

    tsUpdateStatusApprovalNew(v_token) {

        //var resource = "/tsSendForApprove";
        var resource = "/EmtSendForApproveNewApi";

        let formData = new FormData();

        formData.append("v_token", v_token);

        return http.post(resource, formData)


    }


    updateStatusMsg(user, message_id) {

        var resource = "/api/updateStatusMsg";
        let formData = new FormData();

        formData.append("user", user);
        formData.append("message_id", message_id);



        return http.post(resource, formData)


    }



    //system =v_Res_id
    //"v_year" anno periodo timesheet selezionato (obbligatorio)
    //"v_month" mese periodo timesheet selezionato (obbligatorio)
    //"v_user_id" user id della risorsa del timesheet selezionato (obbligatorio)
    //"v_user" user utente collegato codificata in base64, utilizzata come token sicurezza per il metodo (obbligatorio)
    //"v_status" valore dello stato a cui aggiornare il timesheet (1 - Aperto 2 - In Approvazione 3 - Chiuso) (obbligatorio)
    //"v_db_conf" connessione da usare per il metodo, se non viene specificata viene usata connessione db corrente

    tsUpdateStatus(token, system, user_id, status, year, month, user) {

        var resource = "/tsUpdateStatus";
        let formData = new FormData();

        //formData.append(  "v_res_id" ,  system   );
        console.log(system);
        formData.append("v_user_id", user_id);


        formData.append("v_user", token);
        formData.append("v_status", status);


        formData.append("v_year", year);
        formData.append("v_month", month);

        formData.append("username", user);

        return http.post(resource, formData)


    }


    tsUpdateMultiStatus(token, system, user_id, status, year, month, user) {

        var resource = "/tsUpdateMultiStatus";
        let formData = new FormData();

        //formData.append(  "v_res_id" ,  system   );
        console.log(system);
        formData.append("v_user_id", user_id);


        formData.append("v_user", token);
        formData.append("v_status", status);


        formData.append("v_year", year);
        formData.append("v_month", month);

        formData.append("username", user);

        return http.post(resource, formData)


    }



    tsDayUpdate(username, v_res_id, day, year, month, v_ore_presenza, v_ore_assenza, v_ore_straordinari, v_id_assenza) {

        var resource = "/tsDayUpdate";
        let formData = new FormData();
        formData.append("v_user", username);
        formData.append("v_res_id", v_res_id);

        formData.append("v_giorno", day);
        formData.append("v_anno", year);
        formData.append("v_mese", month);

        formData.append("v_ore_presenza", v_ore_presenza);
        formData.append("v_ore_assenza", v_ore_assenza);
        formData.append("v_ore_straordinari", v_ore_straordinari);
        formData.append("v_id_assenza", v_id_assenza);



        return http.post(resource, formData)


    }


    getgroups(v_user) {
        var resource = "/api/getInfoUser";

        let formData = new FormData();
        formData.append("v_user", v_user);
        //return  axios.post(this.url + resource,   formData ,    {'content-type': 'text/json'}   )
        return http.post(resource, formData)
    }



    //si chiama insertMessage e ha come parametri isgroup (0/1), user_group, sender, msg, title, msg_id e user che è il token in base64 della user con cui sei collegato
    deleteMessage(id, user)
    {
        console.log("delete messages", id);

        var resource = "/api/delMessage";
        let formData = new FormData();


        formData.append("id", id);
        formData.append("username", user);


        return http.post(resource, formData)
    }


//delete FERIE
//il metodo https://app.emtool.eu/deleteHolidaysPermits per eliminare le richieste ha come parametri "v_user" l'utente che sta cancellando la richiesta e "id_richesta" l'id della richiesta che si buole eliminare
async deleteFerie(id, v_user )
{
    console.log("delete messages", id);

    var resource = "/deleteHolidaysPermits";
    let formData = new FormData();


    formData.append("id_richiesta", id);
    formData.append("v_user", v_user);


    return http.get(resource + "?id_richiesta=" + id + "&v_user=" + v_user, formData)
}



async deleteMultiFerie(id, v_user )
{
    console.log("delete messages", id);

    var resource = "/deleteMultiHolidaysPermits";
    let formData = new FormData();


    formData.append("id_richiesta", id);
    formData.append("v_user", v_user);


    return http.get(resource + "?id_richiesta=" + id + "&v_user=" + v_user, formData)
}






async deleteMalattia(id, v_user )
{
    console.log("deleteMalattia", id);

    var resource = "/api/deleteDisease";
    let formData = new FormData();


    formData.append("id_richiesta", id);
    formData.append("v_user", v_user);


    return http.get(resource + "?id_richiesta=" + id + "&v_user=" + v_user, formData)
}


async deleteMultiMalattia(id, v_user )
{
    console.log("deleteMultiMalattia", id);

    var resource = "/api/deleteMultiDisease";
    let formData = new FormData();


    formData.append("id_richiesta", id);
    formData.append("v_user", v_user);


    return http.get(resource + "?id_richiesta=" + id + "&v_user=" + v_user, formData)
}





getMessagesApi(v_user, start, perpage) {
    console.log("Apicall", v_user, start, perpage);


    var resource = "/api/newGetMessagesApi";
    let formData = new FormData();


    // formData.append("now", Date.now());

    // formData.append("user", v_user);
    // formData.append("start", start);
    // formData.append("perpage", perpage);

    formData.append("username", v_user);
    formData.append("productid", "emt");

    return http.post(resource, formData)
}



insertMessage(user_group, user, sender, title, msg, isgroup , file) {

    console.log("Insert messages", user_group, title, msg, isgroup);

    console.log ("file" , file );

    var resource = "/api/AlertMsgInsertMessage";
    let formData = new FormData();
    //var  urlApi =  "https://app.emtool.eu/";
    //var  urlApi =  "http://emtool.local/public/";

    var urlApi = main_domain;

    if (file && (file != undefined))
    {

        formData.append( "file" ,  file[0] );

    }


    formData.append("user_group", user_group);
    formData.append("user", user);
    formData.append("sender", sender);
    formData.append("title", title);
    formData.append("msg", msg);
    formData.append("isgroup", isgroup);
    formData.append("send_notify", 1 );
    formData.append("productid", "emt");

    var httpFile = axios.create({
        baseURL: urlApi,
        headers: {
            'Content-Type':"multipart/form-data"
        }
    })


    return httpFile.post(resource, formData)
}



    //
    getDisease(v_user, start, perpage , azienda ,currStatus,search) {
        console.log("Apicall", v_user, start, perpage);


        var resource = "/api/getDisease";
        let formData = new FormData();

        formData.append("v_user", v_user);

        formData.append("now", Date.now());

        formData.append("start", start);
        formData.append("perpage", perpage);

        if ( azienda.length > 0  )
            formData.append("azienda", azienda);

        if ( currStatus.length > 0  )
            formData.append("curStatus", currStatus);

            if ( search.length > 0  )
            formData.append("search", search);


        return http.post(resource, formData)
    }

    getHoliday(v_user, start, perpage , azienda ,currStatus,search) {
        console.log("Apicall", v_user, start, perpage);


        var resource = "/api/getHolidaysPermits";
        let formData = new FormData();

        formData.append("v_user", v_user);

        formData.append("now", Date.now());

        formData.append("start", start);
        formData.append("perpage", perpage);

        if ( azienda.length > 0  )
            formData.append("azienda", azienda);

        if ( currStatus.length > 0  )
            formData.append("curStatus", currStatus);

            if ( search.length > 0  )
            formData.append("search", search);


        return http.post(resource, formData)
    }

    updateStatusMsgApi(user, message_id) {

        var resource = "/api/updateStatusMsgApi";
        //var resource = "http://emtool.local/public/api/updateStatusMsgApi";

        let formData = new FormData();

        formData.append("user", user);
        formData.append("message_id", message_id);



        return http.post(resource, formData)


    }

    getMessages(v_user, start, perpage) {
        console.log("Apicall", v_user, start, perpage);


        var resource = "/api/newGetMessages";
        let formData = new FormData();


        formData.append("now", Date.now());

        formData.append("user", v_user);
        formData.append("start", start);
        formData.append("perpage", perpage);

        return http.post(resource, formData)
    }

    LoadLogoCompany(token) {
        var resource = "/tsLoadLogoCompany";
        

        let formData = new FormData();
        formData.append("v_user", token);


        return http.post(resource, formData)

    }

    LoadLogoCompanyNew(token, nome_folder_prod) {
        var resource = "/tsLoadLogoCompany";
        

        let formData = new FormData();
        formData.append("v_user", token);
        formData.append("v_nome_folder_prod", nome_folder_prod);


        return http.post(resource, formData)

    }



    GetUserGroups(token) {
        var resource = "/tsGetGroups";
        

        let formData = new FormData();
        formData.append("v_user", token);


        return http.post(resource, formData)

    }
    

    GetTimesheet(token, system, year, month) {
        //tsGetTimesheet
        //v_res_id
        //var resource = "/tsGetTimesheet";
        var resource = "/tsGetTimesheetNew";
        
        // var data = { "v_res_id" : system , "v_user" : token , "v_year" : year , "v_month" : month   } ;



        let formData = new FormData();
        formData.append("v_res_id", system);
        formData.append("v_user", token);
        formData.append("v_year", year);
        formData.append("v_month", month);


        //return  axios.post(this.url + resource,   formData ,    {'content-type': 'text/json'}   )
        return http.post(resource, formData)


    }

    login(token) {
        http.defaults.headers.common.Authorization = `Bearer ${token}`
    }

    logout() {
        http.defaults.headers.common.Authorization = ''
    }

    // REST Methods
    find({ resource, query }) {
        return http.get(resource, {
            params: query
        })
    }

    get({ resource, id, query }) {
        return http.get(`${resource}/${id}`, {
            params: query
        })
    }

    create({ resource, data, query }) {
        return http.post(resource, data, {
            params: query
        })
    }

    update({ resource, id, data, query }) {
        return http.patch(`${resource}/${id}`, data, {
            params: query
        })
    }

    destroy({ resource, id }) {
        return http.delete(`${resource}/${id}`)
    }
}

export default new APIProvider({
    url: main_domain /* env('API_URL')  */ // We assume 'https://api.example.com/v1' is set as the env variable
})
